
import ApiService from "@/core/services/ApiService";
import { defineComponent, ref } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useBus } from "../../../../bus";
import { Field } from "vee-validate";
import { DrawerComponent } from "@/assets/ts/components/_DrawerComponent";

export default defineComponent({
  name: "kt-drawer-approve-trainee",
  components: { Field },

  data() {
    return {
      load: false,
      formData: {
          entity_id: "",
          training_institute_id: "",
          course_info_id: "",
          tranche_id: "",
          batch_info_id: "",
          reference_number: "",
      },
      institutes: [],
      associations: [],
      tranches: [],
      courses: [],
      batches: [],
      loading: false,
    };
  },
  async created() {
    this.emitter.on("trainee-approve-modal-data", async (data) => {
      this.formData = data;
    });
    // await this.getTranches();
    // await this.associationList();
    // await this.trainingInstitute();
    // await this.getCourseList();
    // await this.getBatchList();
  },
  methods: {
    async formSubmit() {
     let data = this.formData;

      this.loading = true;
      await ApiService.post("trainee/approve", data)
        .then((response) => {
          DrawerComponent?.hideAll();
          this.loading = false;
          if (response.status == 200) {
            this.emitter.emit("trainee-infos-updated", true);
            Swal.fire({
              title: "Success!",
              text: response.data.data,
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok",
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
          } else {
            let err = "";
            for (const field of Object.keys(response.data.errors)) {
              err += response.data.errors[field][0] + "<br>";
            }
            Swal.fire({
              title: "Please check all the required field",
              html: err,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Close",
              customClass: {
                confirmButton: "btn btn-danger",
              },
            });
          }
        })
        .catch(({ response }) => {
          this.loading = false;
          Swal.fire({
            title: "Unknown error",
            html: response.data.error,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Close",
            customClass: {
              confirmButton: "btn btn-danger",
            },
          });
          console.log(response);
        });
    },
    async getTranches() {
      await ApiService.get("configurations/tranche/list")
        .then((response) => {
          this.tranches = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async associationList() {
      await ApiService.get("entity/list")
        .then((response) => {
          this.associations = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async trainingInstitute() {
      await ApiService.get("institute/list?entity_id=" + this.formData.entity_id)
        .then((response) => {
          this.institutes = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getCourseList() {
      await ApiService.get(
        "course/list?entity_id=" +
          this.formData.entity_id +
          "&tranche_id=" +
          this.formData.tranche_id +
          "&training_institute_id=" +
          this.formData.training_institute_id
      )
        .then((response) => {
          this.courses = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
     async getBatchList() {
      await ApiService.get(
        "batch/list?entity_id=" +
          this.formData.entity_id +
          "&course_info_id=" +
          this.formData.course_info_id +
          "&institute_info_id=" +
          this.formData.training_institute_id
      )
        .then((response) => {
          this.batches = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
  },
});
